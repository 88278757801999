@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
.onboard-top-center-txt {
  text-align: center;
}
.onboard-top-center-txt img {
  transform: translateY(40%);
  width: 80%;
  justify-content: center;
  text-align: center;
}
.onboard-top-row {
  margin-top: 40%;
  justify-content: space-evenly;
  width: 100%;
}
.onboard-top-row label {
  font-weight: 900;
  font-size: 22px;
  color: #035793;
  margin-top: 7px;
  cursor: pointer;
}
.onboard-top-row img {
  width: 3rem;
  float: right;
  cursor: pointer;
}
.onboard-bottom-center-txt {
  text-align: center;
}
.onboard-bottom-center-txt img {
  transform: translateY(20%);
  width: 70%;
}
.phone-input {
  margin-left: 10px;
}
.otp-verify-btn {
  width: 460px;
  height: 40px;
  border-radius: 8px;
  color: white;
  background-color: #035793;
  border: 1px solid #035793;
  font-size: 16px;
  margin-top: 5px;
  margin-top: 20px;
  cursor: pointer;
}
.setting-form-item {
  margin-top: 20px;
}
.setting-form {
  display: table-caption;
  margin-top: 25px;
}
.setting-form-item input {
  width: 400px;
  height: 40px;
  padding-left: 15px;
  border: 1px solid #035793;
  border-radius: 8px;
}
.setting-form-item button {
  width: 400px;
  height: 40px;
  border: 1px solid #035793;
  border-radius: 8px;
  background-color: #035793;
  color: white;
}
.subscription-btn {
  margin-top: 20px;
}
.subscription-btn button {
  width: 400px;
  height: 40px;
  border: 1px solid #035793;
  border-radius: 8px;
  background-color: #035793;
  color: white;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  display: none;
}
.p-steps .p-steps-item:before {
  display: none !important;
}
.p-steps-item {
  flex: 0.1 1 auto !important;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: #035793 !important;
  border-bottom: 2px solid #035793;
  padding-bottom: 5px;
}
.step-body {
  margin: 30px;
}
.language label {
  font-weight: bold;
}
.language {
  display: grid;
}
.dropdown-language {
  width: 50%;
}
.info-msg {
  width: 57%;
  box-shadow: 0px 0px 2px 2px #e2e2e2;
  background-color: #e2e2e2;
  margin-top: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}
.info-msg p {
  padding: 4px;
}
.subscription-plan {
  text-align: center;
}
.subscription-h2 {
  margin: 30px;
  color: #035793;
}
.payment-history {
  box-shadow: 0 0 2px #7d848d;
  width: 70%;
  background-color: #f7f7f9;
  border: 1px solid #f7f7f9;
  border-radius: 8px;
  margin-top: 10px;
}
.payment-history th {
  padding: 10px;
}
.payment-history td {
  padding: 10px;
}
.payment-btn {
  background-color: #035793;
  width: 70%;
  height: 42px;
  border: 1px solid #035793;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  color: white;
}
.statistics {
  display: flex;
  justify-content: center;
  transform: translateY(70%);
}
.statistics-lbl {
  display: flex;
  justify-content: center;
  transform: translateY(174px);
}
.svg-icon {
  width: 25px;
  height: 20px;
  margin-right: 5px;
}
.active-route {
  color: white !important;
  fill: white !important;
  fill-opacity: 1;
}
.nav-link {
  display: flex !important;
}
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.user-name-input {
  border: none;
  margin-top: 0px !important;
  height: 28px !important;
  border-bottom: 0px solid #e2e2e2;
  padding-bottom: 6px;
}
.main-cont-cvc {
  display: flex;
  justify-content: space-between;
}
.w-48 {
  width: 48%;
}
.stripe-input-style {
  border-bottom: 1px solid #e2e2e2;
  height: 26px;
}
.stripe-label {
  color: #7d848d;
}
.cont-div {
  border-bottom: 1px solid #e2e2e2;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

input,
textarea,
button {
  margin: 10px !important;
}
label {
  margin-left: 10px;
}
.pricing-container {
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
}
.sidebar-container {
  width: 300px;
  height: 100%;
  background-color: #035793;
  position: fixed;
  top: 0;
  left: -300px;
  transition: left 0.3s ease;
  z-index: 1;
}
.sidebar-container.open {
  left: 0;
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

/* New Styles */
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  margin-top: 3rem;
}

.logo img {
  width: 7rem;
}

.onboard-left-center-txt img {
  position: absolute;
  transform: translateY(140%);
}

.onboard-bottom-row {
  position: absolute;
  bottom: 20px;
  width: 96%;
}

.onboard-bottom-row label {
  font-weight: 900;
  font-size: 22px;
  color: #035793;
  margin-top: 7px;
  cursor: pointer;
}

.onboard-bottom-row img {
  margin-right: 34px;
  width: 3rem;
  float: right;
  cursor: pointer;
}

.onboard-right-top {
  text-align: center;
  color: white;
}

.onboard-right-center-txt {
  text-align: center;
}

.onboard-right-center-txt img {
  transform: translateY(40%);
  width: 55%;
}

.onboard-bottom-center-txt {
  text-align: center;
}
.onboard-bottom-center-txt img {
  transform: translateY(20%);
  width: 70%;
}
.available{
  display: flex;
  align-items: self-start;
}
.onboard-bottom-row-right {
  display: flex;
  justify-content: space-evenly;
  bottom: 20px;
  position: absolute;
  width: -webkit-fill-available;
}

.onboard-top-row {
  margin-top: 40%;
  justify-content: space-evenly;
  width: 100%;
}

.onboard-top-row label {
  font-weight: 900;
  font-size: 22px;
  color: #035793;
  margin-top: 7px;
  cursor: pointer;
}

.onboard-top-row img {
  width: 3rem;
  float: right;
  cursor: pointer;
}

.onboard-top-top {
  margin-top: 3rem;
  text-align: center;
  color: white;
}
.onboard-top-center-txt {
  text-align: center;
}

.onboard-top-center-txt img {
  transform: translateY(40%);
  width: 80%;
  justify-content: center;
  text-align: center;
}

.right-center {
  width: 100%;
  height: 470px;
  text-align: center;
  display: flex;
  transform: translateY(40%);
  justify-content: center;
}
.bottom-center {
  width: 100%;
  height: 250px;
  text-align: center;
  display: flex;
  transform: translateY(20%);
  justify-content: center;
}

.right-center img {
  position: absolute;
}

.bottom-center img {
  position: absolute;
}

.comming-soon {
  margin: auto;
  width: 22%;
}

.login-heading {
  color: #035793;
}

.login-subheading {
  color: #7d848d;
}

.login-form {
  width: 100%;
  display: inline-grid;
  text-align: left;
}

.login-form label {
  font-weight: bold;
  margin-top: 20px;
}

.login-form input {
  width: auto;
  height: 40px;
  border-radius: 8px;
  background-color: #f7f7f9;
  border: 1px solid #f7f7f9;
  font-size: 16px;
  margin-top: 5px;
  padding-left: 20px;
}

.login-form button {
  width: auto;
  height: 40px;
  border-radius: 8px;
  color: white;
  background-color: #7d848d;
  border: 1px solid #7d848d;
  font-size: 16px;
  margin-top: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.login-form textarea {
  border-radius: 8px;
  background-color: #f7f7f9;
  border: 1px solid #f7f7f9;
  font-size: 16px;
  margin-top: 5px;
  padding-left: 20px;
}

.login-bottom-txt {
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
}

.login-bottom-txt span {
  color: #035793;
  cursor: pointer;
}

.otp-input {
  width: 100%;
  display: inline-grid;
  text-align: left;
}

.otp-input button {
  width: auto;
  height: 40px;
  border-radius: 8px;
  color: white;
  background-color: #035793;
  border: 1px solid #035793;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}

.otp-input input {
  width: 70px;
}

.PhoneInput input {
  width: auto;
}

.signup-bottom-txt {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.signup-bottom-txt span {
  color: #035793;
  cursor: pointer;
}

.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 1px #ddd;
  margin-bottom: 1rem;
  max-width: 260px;
  padding: 1rem 0;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 1rem;
  background-color: #f7f7f9;
  /* min-height: 38.5rem; */
  min-width: 260px !important;
}

.card:hover {
  background-color: #035793;
  /* height: 40rem; */
}

.most-popular {
  font-size: 15px;
  font-weight: 700;
  background-color: #9b59b6;
  color: #fff;
  padding: 0.25em 1rem;
  border-radius: 15px;
  text-transform: uppercase;
}

.card-free {
  border-top: 6px solid #1abc9c;
}

.card-basic {
  border-top: 6px solid #3498db;
}

.card-medium {
  border-top: 6px solid #9b59b6;
}

.card-pro {
  border-top: 6px solid #f1c40f;
}

.card-billing p {
  margin: 0px;
}

.card-billing .price {
  font-size: 50px;
  font-weight: 700;
}

.card-features {
  min-height: 150px;
  text-align: justify;
  margin-top: 20px;
}

.not-available {
  color: #7d848d;
  display: flex;
}

.card-action button {
  border: 3px solid #035793;
  background-color: #035793;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 0.5rem 2rem 0.5rem 2rem;
  margin: 20px;
  transition: 0.3s ease all;
  border-radius: 8px;
}

.trial {
  color: #035793;
}

.card:hover .trial {
  color: white;
}

.card:hover .card-action button {
  color: #035793;
  border: 3px solid white;
  background-color: white;
}

.card-action button:hover {
  transition: 0.3s ease all;
  background-color: #3498db;
  color: #fff;
}

.card-description h2 {
  border: 1px solid #035793;
  border-radius: 8px;
  width: max-content;
  padding: 5px 12px 5px 12px;
  display: inline-block;
  color: #035793;
}

.card:hover .card-description h2 {
  color: white;
}

.card:hover .card-billing p {
  color: white;
}

.card:hover .available {
  color: white;
}

.card:hover .not-available {
  color: #81a7ba;
}

.per-month {
  color: #7d848d;
}

.payment-methods {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin: 10px;
  border: 1px solid white;
  box-shadow: 0 0 2px #7d848d;
  border-radius: 8px;
  height: 45px;
}

.single-card {
  margin-left: 20px;
  position: relative;
}

.single-card label {
  position: absolute;
  top: -9px;
  left: 63px;
  width: max-content;
}

.single-card img {
  position: absolute;
  top: 12px;
}

.new-card-form {
  width: 100%;
  display: inline-grid;
  text-align: left;
}

.new-card-form label {
  font-weight: bold;
  margin-top: 20px;
}

.new-card-form input {
  width: auto;
  height: 40px;
  font-size: 16px;
  margin-top: 15px;
  outline: 0;
  border-width: 0 0 2px;
}

.proceed-to-pay {
  width: auto;
  height: 40px;
  border-radius: 8px;
  color: white;
  background-color: #7d848d;
  border: 1px solid #7d848d;
  font-size: 16px;
  margin-top: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.scan {
  width: 200px;
  background-color: #035793;
  height: 40px;
  border-radius: 8px;
  color: white;
  border: 1px solid #035793;
  font-size: 16px;
  margin-top: 5px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-top: 10px;
}

.scan img {
  margin-right: 5px;
}

.card-secret {
  display: flex;
}

.card-secret input {
  width: 90%;
}

.expiry {
  margin-right: 10px;
  display: grid;
}

.gotit-btn {
  text-align: center;
}

.gotit-btn button {
  background-color: #035793;
  border-color: #035793;
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}

.page-sidebar {
  background-color: #035793;
  height: auto;
  min-height: 100vh;
}

.logo-img {
  margin: 20px;
}

.sidebar-item {
  color: rgba(255, 255, 255, 0.7);
  font-size: large;
  width: max-content;
}

.logout {
  position: absolute;
  bottom: 10px;
  left: 50px;
  display: flex;
}

.logout button {
  background-color: #035793;
  color: white;
  border: 1px solid;
  border-radius: 15px;
  padding: 4px 20px 4px 25px;
}

.setting-body {
  margin: 10px;
}
.setting-body h2 {
  color: #035793;
  font-size: 12px;
}
.cardLabelPrice{
  font-size: 12px;
  margin-left: 8px;
  margin-right: 8px;
  line-height: 18Pnopx;
}
.setting-form {
  width: 100%;
  display: inline-grid;
  margin-top: 25px;
}

.setting-form input {
  width: auto;
  height: 40px;
  padding-left: 15px;
  border: 1px solid #035793;
  border-radius: 8px;
}

.setting-form button {
  width: auto;
  height: 40px;
  border: 1px solid #035793;
  border-radius: 8px;
  background-color: #035793;
  color: white;
}

.subscription-btn {
  margin-top: 10px;
}

.subscription-btn button {
  width: 400px;
  height: 40px;
  margin: 0 !important;
  border: 1px solid #035793;
  border-radius: 8px;
  background-color: #035793;
  color: white;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  display: none;
}

.p-steps .p-steps-item:before {
  display: none !important;
}

.p-steps-item {
  flex: 0.1 1 auto !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: #035793 !important;
  border-bottom: 2px solid #035793;
  padding-bottom: 5px;
}

.step-body {
  margin: 10px;
}

.language label {
  font-weight: bold;
}

.language {
  display: grid;
}

.dropdown-language {
  width: auto;
}

.info-msg {
  width: 100%;
  box-shadow: 0px 0px 2px 2px #e2e2e2;
  background-color: #e2e2e2;
  margin-top: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}

.info-msg p {
  padding: 4px;
}

.subscription-plan {
  text-align: center;
}

.subscription-h2 {
  margin: 30px;
  color: #035793;
}

.payment-history {
  box-shadow: 0 0 2px #7d848d;
  width: 100%;
  background-color: #f7f7f9;
  border: 1px solid #f7f7f9;
  border-radius: 8px;
  margin-top: 10px;
}

.payment-history th {
  padding: 10px;
}

.payment-history td {
  padding: 10px;
}

.payment-btn {
  background-color: #035793;
  width: 100%;
  height: 42px;
  margin: 0px !important;
  margin-top: 10px !important;
  border: 1px solid #035793;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  color: white;
}

.statistics {
  display: flex;
  justify-content: center;
  transform: translateY(70%);
}

.statistics-lbl {
  display: flex;
  justify-content: center;
  transform: translateY(174px);
}

.svg-icon {
  width: 25px;
  height: 20px;
  margin-right: 5px;
}

.active-route {
  color: white !important;
  fill: white !important;
  fill-opacity: 1;
}

.nav-link {
  display: flex !important;
}

.sidebar-container {
  width: 300px;
  height: 100%;
  background-color: #035793;
  position: fixed;
  top: 0;
  left: -300px;
  transition: left 0.3s ease;
  z-index: 1;
}

.sidebar-container.open {
  left: 0;
}

.toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 8px 12px;
  background-color: #035793;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
}

.custom-side {
  width: 100%;
  box-sizing: border-box;
}

.icon-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px; /* Adjust padding to control button size */
  transition: background-color 0.2s;
}

.icon-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.icon {
  width: 26px; /* Adjust icon size */
  height: 26px; /* Adjust icon size */
}
.white-icon {
  filter: invert(100%) grayscale(0);
}

.container {
  text-align: center;
}

.logo img {
  max-width: 100%;
}

.logo {
  text-align: center;
}

.login-heading {
  text-align: center;
}

.login-subheading {
  text-align: center;
}

input,
textarea,
button {
  margin: 10px !important;
}

label {
  margin-left: 10px;
}

.phone-input {
  margin-left: 10px;
}

.pricing-container {
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
}

/* Styles for mobile (screen width less than 768px) */
@media screen and (max-width: 767px) {
  .dialog {
    width: 80%;
    text-align: center;
  }
  .pricing-scroll-container {
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    scrollbar-width: none;
  }
  .pricing-scroll-container::-webkit-scrollbar {
    width: 0; /* Hide scrollbar on Chrome, Safari, and newer Edge */
  }
  .pricing-container {
    display: flex;
    flex-direction: row;
  }
}

/* Styles for desktop (screen width greater than or equal to 768px) */
@media screen and (min-width: 768px) {
  .dialog {
    width: 35%;
    text-align: center;
  }
  .pricing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .desktop-hide {
    display: none !important;
  }
  .custom-side {
    width: calc(100% - 300px);
    margin-left: 300px;
  }
  .login-form {
    width: auto;
  }
  .login-form input {
    width: 460px;
  }
  .login-form button {
    width: 460px;
  }
  .otp-input {
    width: auto;
  }
  .otp-input button {
    width: 460px;
  }
  .new-card-form {
    width: auto;
  }
  .new-card-form proceed-to-pay {
    width: 460px;
  }
  .info-msg {
    width: 500px;
    margin: 10px;
  }
  .setting-form {
    width: auto;
  }
  .setting-form input {
    width: 500px;
  }
  .setting-form button {
    width: 500px;
  }
  .dropdown-language {
    width: 500px;
  }
  .payment-history {
    width: 70%;
  }
  .payment-btn {
    width: 70%;
  }
}
